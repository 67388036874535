<template>
  <div id="main">
    <DFSHeader />
    <Content />
    <DFSFooter />
  </div>
</template>

<script>
import DFSHeader from '@/components/DFSHeader';
import Content from './components/Content';
import DFSFooter from '@/components/DFSFooter';

export default {
  name: 'Login',
  components: {
    DFSHeader,
    Content,
    DFSFooter
  }
}
</script>

<style scoped>
</style>
