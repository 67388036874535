<template>
  <div id="main-content">
    <a-card
      title="请登录"
      class="login-card"
      :bordered="true"
    >
      <a-form-model
        class="login-form"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
      >
        <a-form-model-item label="用户名：">
          <a-input v-model="form.username" placeholder="请输入用户名" />
        </a-form-model-item>
        <a-form-model-item label="密码：">
          <a-input v-model="form.password" type="password" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{offset: 4}">
          <a-button
            type="primary"
            html-type="submit"
            :disabled="form.username === '' || form.password === ''"
            @click="onLogin"
          >
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import {login, me} from '@/services/user';
import storage from '@/utils/storage';

export default {
  name: 'Content',
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    onLogin() {
      login(
          this.form.username,
          this.form.password
      ).then(({data}) => {
        storage.setToken(data['data']['access']);
        me().then(({data}) => {
          storage.removeUserInfo();
          storage.setUserInfo(data['data']);
          this.$router.push({name: 'Index'});
        });
      });
    }
  }
}
</script>

<style scoped lang="stylus">
#main-content
  padding-top: 150px;

.login-card
  width: 600px
  margin: 0 auto
</style>
